import { FUEL_PRODUCT_COLORS, WHITE_SMOKE_COLOR } from '../../utils/constants';
import { isNotNullValue } from '../../utils/formUtil';

/*
const METADATA_MAP = {
  '50 PPM': {
      type: 'ppm50',
      label: '50 PPM',
      color: '#CDDC39',
  },
  'ULP 93': {
      type: 'ulp93',
      label: 'ULP 93',
      color: '#4CAF50',
  },
  'ULP 95': {
      type: 'ulp95',
      label: 'ULP 95',
      color: '#3D5AFE',
  },

};
*/

export const formatPercentageValue = (metadata) => {
  const _value = metadata?.value;
  if (isNotNullValue(_value)) {
    return _value + '%';
  }

  return '';
}

export const findCardType = (cards, type) => {
  if (cards?.length) {
    return cards?.find(c => type.toLowerCase() === c?.type.toLowerCase());
  }

  return null;
}

export const findCardMetaType = (metadata, type) => {

  if (metadata?.length) {
    return metadata?.find(m => type.toLowerCase() === m?.type.toLowerCase());
  }

  return null;
}

export const findCardTypeValue = (cards, type) => {
  const details = findCardType (cards, type);
  if (details){
    return { type: details?.type, value: details?.value}
  }

  return null;
}

export const transformBulkOrderBreakdownData = (data) => {
  const details = findCardType (data, 'Bulk and Bulk Breaks');
  const bulkMeta = findCardMetaType (details?.metadata, 'bulk orders');
  const breakMeta = findCardMetaType (details?.metadata, 'bulk break orders')

  const bulkMetaL2 = findCardMetaType (bulkMeta?.metadata, 'count');
  const breakMetaL2 = findCardMetaType (breakMeta?.metadata, 'count')

  const bulkMetaL3 = findCardMetaType (bulkMetaL2?.metadata, 'percentage');
  const breakMetaL3 = findCardMetaType (breakMetaL2?.metadata, 'percentage')

  return {
    type: 'Bulk and Bulk Breaks',
    value: bulkMetaL3?.value || 0,
    tip: `Total of ${bulkMetaL2?.value || 0} bulk, ${breakMetaL2?.value || 0} break orders`,
    leftBar: {
      label: formatPercentageValue(bulkMetaL3),
      backgroundColor: "#000000",
      textColor: "#FFF",
      legend: 'Bulk Orders'
    },
    rightBar: {
      label: formatPercentageValue(breakMetaL3),
      backgroundColor: "#1565c0",
      textColor: "#FFF",
      legend: 'Bulk Break Orders'
    }
  }
}

export const transformProgressData = (data, type) => {
  const details = findCardType (data, type);
  const trend = findCardMetaType (details?.metadata, 'Trend');
  const indicator = findCardMetaType (details?.metadata, 'Indicator');
  const progress = findCardMetaType (details?.metadata, 'Progress');
  const diff = findCardMetaType (details?.metadata, 'Diff');

  return {
    type: type,
    value: details?.value,
    metadata: {
      trend: trend?.value,
      progress: progress?.value || 0,
      indicator: indicator?.value,
      diff: diff?.value,
    }
  }
}

export const transformTallyData = (data, type, color) => {
  const details = findCardType (data, type);
  const ppm50 = findCardMetaType (details?.metadata, '50 PPM');
  const ulp93 = findCardMetaType (details?.metadata, 'ULP 93');
  const ulp95 = findCardMetaType (details?.metadata, 'ULP 95');

  return {
    type: type,
    value: details?.value,
    color: color || "#0000ff",
    metadata: {
      trend: ppm50?.value,
      ulp95: ulp95?.value,
      ulp93: ulp93?.value,
    }
  }
}

export const transformProductSegmentData = (details) => {
  // const details = data['Product Segmentation'];

  const ppm50 = findCardMetaType (details?.metadata, '50 PPM');
  const ulp93 = findCardMetaType (details?.metadata, 'ULP 93');
  const ulp95 = findCardMetaType (details?.metadata, 'ULP 95');

  return {
    ppm50: {
      value: findCardMetaType(ppm50?.metadata, 'percentage')?.value || 0,
      leftBar: {
        backgroundColor: FUEL_PRODUCT_COLORS.ppm50,
      },
      rightBar: {
        label: formatPercentageValue(ppm50?.value ),
        backgroundColor: WHITE_SMOKE_COLOR,
      }
    },
    ulp93: {
      value: findCardMetaType(ulp93?.metadata, 'percentage')?.value || 0,
      leftBar: {
        backgroundColor: FUEL_PRODUCT_COLORS.upl93,
      },
      rightBar: {
        label: formatPercentageValue(ulp93?.value ),
        backgroundColor: WHITE_SMOKE_COLOR,
      }
    },
    ulp95: {
      value: findCardMetaType(ulp95?.metadata, 'percentage')?.value || 0,
      leftBar: {
        backgroundColor: FUEL_PRODUCT_COLORS.upl95,
      },
      rightBar: {
        label: formatPercentageValue(ulp95?.value ),
        backgroundColor: WHITE_SMOKE_COLOR,
      }
    },
  }
}

export const removeCardType = (cardsData, type) => {
  if (cardsData?.length) {
    return cardsData?.filter(c => type.toLowerCase() !== c?.type.toLowerCase());
  }

  return [];

}

export const transformExcusionTally = (cardsData, type) => {
  return removeCardType(cardsData, type)
    ?.map(card => transformTallyData(cardsData, card?.type));
}
