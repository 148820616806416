// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginTop: '20px',
          width: '100%',
          overflowX: { xs: 'scroll', sm: 'auto' },
          borderRadius: '20px 20px 0px 0px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td, &:last-child th': {
            border: 0
          },
          '&:hover': {
            backgroundColor: 'rgba(39,226,245,0.27)'
          },
          '&:active': {
            backgroundColor: theme.palette.action.active,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
          },
        }
      }
    }
  };
}
