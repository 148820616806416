import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, Button, FormControl, MenuItem, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import API from '../../../data/api';

import CustomTextField from '../../custom/CustomTextField';
import CustomModal from '../..//custom/CustomModal';
import { MODAL_WIDTH } from '../../../utils/constants';

const initState = {
  clients: [],
  products: [],
  productList: [],
  product: '',
  volume: '',
  client: '',
  purchaseOrder: '',
  alert: '',
  clientError: false,
  purchaseOrderError: false,
  productError: false,
  volumeError: false,
}
export default class SellModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initState };
  }

  componentDidMount() {
    // this.getClients();
    // this.getProducts();
  }
  componentDidUpdate(prevProps) {
    if ((prevProps?.openModal !== this.props.openModal)
      // && this.props.openModal
    ) {
      this.getClients();
      this.getProducts()
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: e.target.value === '',
    })
  }

  getClients = async () => {
    try {
      const res = await API.get('inventory/backoffice/clients');
      if (res.status === 200) {
        this.setState({
          clients: res.data.clients,
        });
      } else {
        console.log('Error fetching clients');
      }
    } catch (error) {
      console.log('Error fetching clients:', error);
    }
  };

  getProducts = async () => {
    try {
      const res = await API.get('inventory/backoffice/products');
      if (res.status === 200) {
        this.setState({
          products: res.data.products,
        });
      } else {
        console.log('Error fetching products');
      }
    } catch (error) {
      console.log('Error fetching products:', error);
    }
  };

  submitOrder = async () => {
    const { client, purchaseOrder, productList } = this.state;
    const endpoint = `inventory/public_facing/order/${client}?purchase_order=${purchaseOrder}`;
    const payload = JSON.stringify(productList);

    const _clientErr = client === '';
    const _productsErr = !productList?.length;
    const _poErr = purchaseOrder === '';
    this.setState({
        clientError: _clientErr,
        purchaseOrderError: _poErr,
        volumeError: _productsErr,
        productError: _productsErr,
    })
    if(_clientErr || _poErr || _productsErr) {
        return
    }

    try {
      const res = await API.post(endpoint, payload);

      if (res.status === 201) {
        this.props.setUpdateStats(true);
        this.props.setUpdateAllOders(true);
        this.handleClose();
      } else {
        this.setState({
          alert: 'There was an error processing your order...',
        });
      }
      this.forceRerender();
    } catch (error) {
      this.setState({
        alert: 'There was an error processing your order.',
      });
      console.log('Error submitting order:', error);
    }
  };

  handleClose = () => {
    const { productList } = this.state;
    productList.splice(0, productList.length);
    this.setState({ ...initState });
    this.props.toggleModalState("buy", false);
  }

  addToList = (product, volume) =>{
    const { productList } = this.state;
    const exists = productList.length > 0 && productList.filter(p => p.product === product).length > 0;

    const _volErr = volume === '';
    const _prodErr = product === '' || exists;
    this.setState({
      'productError': _prodErr,
      'volumeError': _volErr,
    })
    if (_prodErr || _volErr || exists) {
      return
    }

    productList.push({ product, volume });
    this.setState({
      productList: productList,
      product: '',
      volume: '',
    });
  }

  removeFromList= (index) => {
    const { productList } = this.state;
    productList.splice(index, 1);
    this.setState({ productList: productList });
  }

  render() {
    const { clients, products, productList, product, volume, client, purchaseOrder, alert } = this.state;
    const volumeLimit = this.props.orderLimits.volume;
    const volumeErrorMessage = (volumeLimit.max < 1000000
      ? 'Volume must be between ' + volumeLimit.min + ' and ' + volumeLimit.max
      : 'Volume must be ' + volumeLimit.min + ' or more'
    );

    return (
      <Stack>
        <CustomModal
          openModal={this.props.openModal}
          handleCloseModal={this.handleClose}
          modalStyle={{
            width: { ...MODAL_WIDTH.small },
          }}
          modalTitle={'Place Client Order'}
          modalIcon='currency'
        >
              <Typography> <em>Sell</em></Typography>
            <Stack id="modal-modal-description"  sx={{ my: 2 }}>
              <FormControl fullWidth>
                {/* <InputLabel id="client-select-label">Client</InputLabel> */}
                <CustomTextField
                  select
                  required
                  labelId="client-select-label"
                  id="client-select"
                  name="client"
                  value={client}
                  label="Client"
                  onChange={(e) => this.handleChange(e)}
                  error={this.state.clientError}
                  helperText={this.state.clientError? 'Client is required': ''}
                >
                  {clients.map((entry) => (
                    <MenuItem key={entry.company_id} value={entry.company_id}>
                      {entry.company_name}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </FormControl>
              <CustomTextField
                required
                fullWidth
                sx={{ mt: 2 }}
                label="Purchase Order Number"
                name="purchaseOrder"
                value={purchaseOrder}
                onChange={(e) => this.handleChange(e)}
                error={this.state.purchaseOrderError}
                helperText={this.state.purchaseOrderError? '"Purchase order is required': ''}
              />
              <Box container alignItems="center" sx={{  display: 'flex', mt: 2 }}>
                {/* <Grid  size={{ xs: 4 }}> */}
                  <FormControl fullWidth>
                    {/* <InputLabel id="product-select-label">Product</InputLabel> */}
                    <CustomTextField
                      select
                      required
                      labelId="product-select-label"
                      id="product-select"
                      name="product"
                      value={product}
                      label="Product"
                      onChange={(e) => this.handleChange(e)}
                      error={this.state.productError}
                      helperText={this.state.productError? 'Product is required or already added': ''}
                    >
                      {products.map((entry) => (
                        <MenuItem key={entry.name} value={entry.name}>
                          {entry.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </FormControl>
                {/* </Grid>
                <Grid item size={{ xs: 4 }}> */}
                  <FormControl fullWidth>
                    <CustomTextField
                      required
                      id="volume"
                      label="Volume"
                      variant="outlined"
                      name="volume"
                      value={volume}
                      slotProps={{
                        input: {
                          min: volumeLimit.min,
                          max: volumeLimit.max,
                        },
                        inputLabel: { shrink: true}
                      }}
                      type="number"
                      sx={{ ml: 2 }}
                      onChange={(e) => {
                        this.handleChange(e);
                        if (e.target.value < volumeLimit.min || e.target.value > volumeLimit.max) {
                          this.setState({
                            volumeError: true,
                          })
                        }
                    }}
                      error={this.state.volumeError}
                      helperText={this.state.volumeError? volumeErrorMessage: ''}
                    />
                  </FormControl>
                {/* </Grid>
                <Grid item xs={3} sx={{ ml: 2 }}> */}
                  <Button variant="contained" sx={{ ml: 2 }} onClick={() => this.addToList(product, volume)}>
                    Add
                  </Button>
                {/* </Grid> */}
              </Box>
              {productList.length > 0 && (
                <Grid container display='block' alignItems={'center'} sx={{ my: 1 }}>
                  {productList.map((entry, index) => (
                    <Grid container alignItems="right" sx={{ my: 1 }} key={index}>
                      <Grid item size={{ xs: 4 }}>{entry.product}</Grid>
                      <Grid item size={{ xs: 4 }}>{entry.volume}</Grid>
                      <Grid item size={{ xs: 4 }}>
                        <Button variant="contained" onClick={() => this.removeFromList(index)}>-</Button>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Box sx={{ alignItems: 'center' }}>
                <Button color="inherit" variant='contained' sx={{ mt: 2, width: '47%' }} onClick={() => {this.handleClose()}}>Cancel</Button>
                <Button  variant='contained' sx={{ mt: 2, ml: 2,  width: '47%' }} onClick={(e) => {this.submitOrder(e)}}>Submit</Button>
              </Box>
              <Typography sx={{ mt: 2 }}>{alert}</Typography>
            </Stack>
        </CustomModal>
      </Stack>
    );
  }
}

SellModal.propTypes = {
  setUpdateStats: PropTypes.func.isRequired,
  setUpdateAllOders: PropTypes.func.isRequired,
};