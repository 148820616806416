/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ConfirmDialog from "./ConfirmDialog";
import { useAuth } from "../../contexts/AuthContext";
import API from '../../data/api';
import { getTokenExpiry, getRefreshToken, getLastUserInteraction } from '../../utils/authUtil';

export const SessionTimeOut = () => {

  const defaultMinutes = 60;
  const [open, setOpen] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [color, setColor] = useState("#0f4853");
  const [backgroundRefresh, setBackgroundRefresh] = useState(true);
  const [lastUserInteraction, setLastUserInteraction] = useState(60);
  const [forceLogin, setForceLogin] = useState(false);

  const { login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    const interval = setInterval(() => getTimer(), 1000);
    return () => clearInterval(interval);

  }, [minutes, seconds, open, lastUserInteraction, color]);

  const getTimer = () => {
    changeColor();

    const tokenExpiryTime = getTokenExpiry();
    if (tokenExpiryTime) {
      setMinutes(tokenExpiryTime.minutes);
      setSeconds(tokenExpiryTime.seconds);

      setOpen(minutes < 1 && seconds > 0);
      const userInteraction = getLastUserInteraction()?.minutes;
      setLastUserInteraction(userInteraction);
      if (tokenExpiryTime.minutes <= 0 && tokenExpiryTime.seconds <= 0) {
        setForceLogin(true);
        clearSessionData();
      }
      /* Extend current session in the background if it is left with 2 minutes
        and last user interaction with backend is not more than 5 minutes ago */
      else if (tokenExpiryTime.minutes < 2 && backgroundRefresh && lastUserInteraction < 2 ) {
        console.log("Background token refresh")
        setBackgroundRefresh(false);
        extendSession();
      }
      
    } else {
      clearSessionData()
    }
  };

  const handleLogoutClick = () => {
    setOpen(false);
    setForceLogin(true);
    setSeconds(0);
    clearSessionData()
  }

  const clearSessionData = () => {
    setOpen(false);
    logout();
    if(forceLogin){
      setForceLogin(false);
      navigate('/login');
    }
  }

  const changeColor = () => {
    if (minutes < 1 && seconds > 40) setColor("#00c853");
    else if (minutes < 1 && seconds <= 40 && seconds > 20) setColor("#ff6f00");
    else if (minutes < 1 && seconds <= 20) setColor("#d50000");
    else setColor("#212B36");
  };

  const extendSession = async () => {
    setBackgroundRefresh(false);
    const refreshToken = getRefreshToken();
    // console.log("refresh-token: ", refreshToken);
    try {
      const response = await API.post(`auth/refresh-token?refresh_token=${refreshToken}`);

      if (response.status === 200) {
        login(response.data);
        setMinutes(defaultMinutes); // Trick the flicking window on close
        setOpen(false);
        setBackgroundRefresh(true);
      } else{
        console.error("Error refreshing token: " + response.data)
        clearSessionData();
      }
    } catch (err) {
      console.error('Error:', err.response?.data || err.message);
      clearSessionData();
    }
  };

  return (
    <ConfirmDialog
      open={open}
      title="Login Session Timeout"
      cancelText="Logout"
      onCancel={handleLogoutClick}
      onConfirm={extendSession}
      confirmText="Extend Session"
    >
      <p>
        Your session will expire in&nbsp;
        <Typography sx={{ color: { color }, display: 'inline-block', width: '20px' }}>
          {String(seconds).padStart(2, '0')}
        </Typography>
        &nbsp;seconds
      </p>
    </ConfirmDialog>
  );
};
