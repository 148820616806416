import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../../contexts/AuthContext';
import { isUserAuthenticated } from 'src/utils/authUtil';

import API from '../../../data/api';
import CustomTextField from '../../../components/custom/CustomTextField';
import CustomPasswordInput from '../../../components/custom/CustomPasswordInput';
import { extractFormValues, initialiseForm, updateFormValue, validateForm } from '../../../utils/formUtil';

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const initialRealm = queryParams.get('realm') || 'BACKOFFICE'; // Default to 'BACKOFFICE' if not present
  const loginErrorMessage = 'Login failed - ensure username and password are correct';

  // Form fields and their initial state/values
  const initialFormData = {
    username: { value: '', required: true },
    password: { value: '', required: true },
    realm: { value: initialRealm, required: true },
  };
  const initFormState = initialiseForm(initialFormData);

  const [loginError, setLoginError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState( initFormState);

  useEffect(() => {

    if (isUserAuthenticated()) {
      navigate('/dashboard');
    }

  });

  const handleLogin = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if(!validateForm(setFormData, formData)){
      setSubmitted(false);
      return;
    }

    const formValues = extractFormValues(formData);
    try {
      const response = await API.post('auth/login', {...formValues});

      const redirectURL = response.data?.auth_url || '';
      if (redirectURL) {
        console.log("redirectURL: " + redirectURL)
        login(response.data);

        navigate('/dashboard');
      } else {
        const message = response.data;
        handleLoginError(message);
      }
    } catch (err) {
      const message = err.response ? err.response.data : err.message;
      handleLoginError(message);
    }
  };

  const handleLoginError = (message) => {
    setSubmitted(false);
    setLoginError(true);
    console.error(message);
  }

  const handleChange = (e) => {
    setLoginError(false);
    const { name, value } = e.target;
    updateFormValue(setFormData, formData, name, value);

  }

  return (
    <form onSubmit={handleLogin} noValidate>
      <Stack spacing={3}>
        <CustomTextField
          name="username"
          label="Username"
          value={formData.username.value}
          onChange={handleChange}
          required={formData.username.required}
          error={formData.username.error}
          helperText={formData.username.error && formData.username.errorMessage}
        />

        <CustomPasswordInput
          name="password"
          label="Password"
          value={formData.password.value}
          onChange={handleChange}
          required={formData.password.required}
          error={formData.password.error}
          helperText={formData.password.error && formData.password.errorMessage}
        />

          {/*
          <CustomTextField
            select
            labelId="realm-select-label"
            id="realm-select"
            label="Realm"
            name="realm"
            value={formData.realm.value}
            onChange={handleChange}
            required={formData.realm.required}
            error={formData.realm.error}
            helperText={formData.realm.error && formData.realm.errorMessage}
          >
            <MenuItem value="BACKOFFICE">BACKOFFICE</MenuItem>
            <MenuItem value="CLIENT">CLIENT</MenuItem>
            <MenuItem value="LOGISTICS">LOGISTICS</MenuItem>
          </CustomTextField>
          */}
      </Stack>

      { loginError && 
        <p style={{ color: 'red' }}> {loginErrorMessage} </p>
      }

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}> */}
        <LoadingButton
          style={{ backgroundColor: '#3B3297' }}
          sx={{ my: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={submitted}
        >
          Sign In
        </LoadingButton>
      {/* </Stack> */}
    </form>
  );
}
