import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, Button, FormControl, MenuItem, Box, } from '@mui/material';
import API from '../../../data/api';
import CustomTextField from '../../custom/CustomTextField';
import CustomModal from '../../custom/CustomModal';
import CustomDatePicker from '../../custom/CustomDatePicker';
import { extractFormValues, isNotNullValue, isNullValue, validateForm } from '../../../utils/formUtil';
import { MODAL_WIDTH } from '../../../utils/constants';
import { LoadingButton } from '@mui/lab';

const initState = {
  depots: [],
  clearDates: true,
  submitted: false,
  formData: {
    selectedDepot: { value: '', required: true, error: false, errorMessage: 'Please select a depot' },
    ppm50Price: { value: '', required: true, error: false, errorMessage: 'Please provide 50 PPM price' },
    ulp93Price: { value: '', required: true, error: false, errorMessage: 'Please provide ULP 93 price' },
    ulp95Price: { value: '', required: true, error: false, errorMessage: 'Please provide ULP 95 price' },
    startDate: { value: null, required: true, error: false, errorMessage: 'Please specify a start date' },
    endDate: { value: null, required: true, error: false, errorMessage: 'Please specify an end date' },
  }
}

export default class DepotPricingModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...initState }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps?.openModal !== this.props.openModal)
      // && this.props.openModal
    ) {
      this.getDepots();
    }
  }

  getDepots = async () => {
    await API.get('inventory/backoffice/depots?depot_type=pricing')
      .then(res => {
        let _records = [];
        if (res.status === 200) {
          _records = res.data.depots;
        } else {
          console.log("There was an error retrieving depots")
        }
        this.setState({ depots: _records });
      })
      .catch((error) => {
        console.error('There was an error retrieving depots, ', error);
      });
  }

  submitPricing = async (event) => {
    event.preventDefault();

    if (!validateForm(this.setValidatedFormData, this.state.formData)) {
      this.setState({  submitted: false, });
      return;
    }

    this.setState({ submitted: true, });
    const { selectedDepot, ulp93Price, ulp95Price, ppm50Price, startDate, endDate } = extractFormValues(this.state.formData);
    // console.log(selectedDepot, ulp93Price, ulp95Price, ppm50Price, startDate, endDate)
    const endpoint = `inventorybackoffice/enter_selling_price?depot_id=${selectedDepot}&start_date=${startDate}&end_date=${endDate}`;
    const productPrices = [
      { product: 'ULP 93', price: ulp93Price },
      { product: 'ULP 95', price: ulp95Price },
      { product: '50 PPM', price: ppm50Price },
    ];

    await API.post(endpoint, productPrices)
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          this.handleCloseModal();
        } else {
          this.setState({
            alert: 'There was an error processing your order...'
          })
        }
      })
      .catch((error) => {
        this.setState({
          alert: 'There was an error processing your order.',
        });
        console.error('There was an error submitting your order, ', error);
      });
  }

  setValidatedFormData = (formData) => {
    this.setState({
      formData: { ...formData },
    });
  }

  toggleClearDates = () => {
    this.setState({
      clearDates: this.state.clearDates,
    });
  }

  handleCloseModal = () => {
    this.setState({ ...initState });
    this.props.toggleModalState("price", false);
  }

  updateFiledValue = (field, value, data) => {
    const currentData = data || this.state.formData;
    // console.log(field, value, isNullValue(value), currentData)

    const newFormData = {
      ...currentData,
      [field]: {
        value: value,
        error: isNullValue(value),
      } ,
    };
  
    this.setState({
      formData: {...newFormData}
    });

    return newFormData;
  }
  handleValueChange = (e) => {
    this.updateFiledValue(e.target.name, e.target.value);
  }

  handleDepotSelect = (event) => {

    let newFormData = { ...this.state.formData };
    const { name, value } = event.target;
    newFormData = this.updateFiledValue(name, value, newFormData);
    const currentDepot = this.state.depots?.find(d => d.depot_id === value);
    if (isNotNullValue(currentDepot)) {

      currentDepot?.prices?.forEach(item => {
        const price = item.price_details.sell_price;
        const start_date = item.price_details.start_trading_date?.split('T')[0] || null;
        const end_date = item.price_details.end_trading_date?.split('T')[0] || null;
        let product = 'ppm50Price';
        switch (item.product_name) {
          case 'ULP 93':
            product = 'ulp93Price';
            break;
          case 'ULP 95':
            product = 'ulp95Price';
            break;
          default:
            break;
        }
        newFormData = this.updateFiledValue(product, price, newFormData);
        newFormData = this.updateFiledValue('startDate', start_date, newFormData);
        newFormData = this.updateFiledValue('endDate', end_date, newFormData);

      });
    }

  }

  render() {
    const { depots } = this.state;
    return (
      <Stack>
        <CustomModal
          openModal={this.props.openModal}
          handleCloseModal={this.handleCloseModal}
          modalStyle={{
            width: { ...MODAL_WIDTH.small },
          }}
          modalTitle={'Depot Pricing'}
          modalIcon='currency'
        >
          <form onSubmit={this.submitPricing} noValidate>
            <CustomTextField sx={{ my: 3 }}
              fullWidth
              select
              name='selectedDepot'
              label="Select Depot"
              labelId="depot-select-label"
              value={this.state.formData.selectedDepot.value}
              onChange={(e) => { this.handleDepotSelect(e) }}
              required={this.state.formData.selectedDepot.required}
              error={this.state.formData.selectedDepot.error}
              helperText={this.state.formData.selectedDepot.error && this.state.formData.selectedDepot.errorMessage}
            >
              <MenuItem value=" " disabled>
                <em>Select Depot</em>
              </MenuItem>
              {depots.map((item) => (
                <MenuItem key={'sepot_' + item.depot_id} value={item.depot_id}>
                  {item.depot_name}
                </MenuItem>
              ))}
            </CustomTextField>

            <CustomTextField sx={{ my: 1 }}
              fullWidth
              type="number"
              id="ppm50-price"
              variant="outlined"
              name='ppm50Price'
              label={`50 PPM Price`}
              onChange={(e) => { this.handleValueChange(e) }}
              value={this.state.formData.ppm50Price.value}
              required={this.state.formData.ppm50Price.required}
              error={this.state.formData.ppm50Price.error}
              helperText={this.state.formData.ppm50Price.error && this.state.formData.ppm50Price.errorMessage}
            />

            <CustomTextField sx={{ my: 1 }}
              fullWidth
              type="number"
              id="ulp93-price"
              name='ulp93Price'
              variant="outlined"
              label={`ULP 93 Price`}
              onChange={(e) => { this.handleValueChange(e) }}
              value={this.state.formData.ulp93Price.value}
              required={this.state.formData.ulp93Price.required}
              error={this.state.formData.ulp93Price.error}
              helperText={this.state.formData.ulp93Price.error && this.state.formData.ulp93Price.errorMessage}
            />

            <CustomTextField sx={{ my: 1 }}
              fullWidth
              type="number"
              id="ulp95-price"
              variant="outlined"
              name='ulp95Price'
              label={`ULP 95 Price`}
              onChange={(e) => { this.handleValueChange(e) }}
              value={this.state.formData.ulp95Price.value}
              required={this.state.formData.ulp95Price.required}
              error={this.state.formData.ulp95Price.error}
              helperText={this.state.formData.ulp95Price.error && this.state.formData.ulp95Price.errorMessage}
            />

            <FormControl fullWidth >
              <CustomDatePicker sx={{ overflow: 'hidden' }}
                label={"Start Date"}
                field={'startDate'}
                currentValue={this.state.formData.startDate.value}
                handleChange={this.updateFiledValue}
                clear={this.state.clearDates}
                setClear={this.toggleClearDates}
                dateError={this.state.formData.startDate.error && 'invalidDate'}
              />
            </FormControl>

            <FormControl sx={{ mt: 1, }} fullWidth>
              <CustomDatePicker
                required
                label={"End Date"}
                field={'endDate'}
                currentValue={this.state.formData.endDate.value}
                handleChange={this.updateFiledValue}
                clear={this.state.clearDates}
                setClear={this.toggleClearDates}
                minDate={this.state.startDate}
                dateError={this.state.formData.endDate.error && 'invalidDate'}
              />
            </FormControl>

            <Box sx={{ alignItems: 'center',  mt: 3, }}>
              <Button color="inherit"
                variant='contained'
                sx={{ width: '47%' }}
                onClick={() => { this.handleCloseModal() }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                sx={{ml: 2, width: '47%' }}
                type="submit"
                loading={this.state.submitted}
              // onClick={(e) => { this.submitPricing(e) }}
              >
                Submit
              </LoadingButton>
            </Box>
            <Typography sx={{ mt: 2 }}>
              {this.state.alert}
            </Typography>
          </form>
        </CustomModal>
      </Stack>
    )
  }
}

DepotPricingModal.propTypes = {
  setUpdateStats: PropTypes.func.isRequired,
  setUpdateAllOders: PropTypes.func.isRequired,
};
