import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import { Stack, Typography, Button, FormControl, MenuItem, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

import API from '../../../data/api';
import CustomTextField from '../../custom/CustomTextField';
import { MODAL_WIDTH } from 'src/utils/constants';
import CustomModal from 'src/components/custom/CustomModal';

const initState = {
    suppliers: [],
    products: [],
    productList: [],
    product: '',
    volume: '',
    supplier: '',
    openCheckModal: false,
    alert: '',
    supplierError: false,
    productError: false,
    volumeError: false,
}
export default class BuyModal extends React.Component{

    constructor(props){
        super(props);
        this.state = { ...initState }
    }

    componentDidMount (){
      // this.getSuppliers()
      // this.getProducts()
    }

    componentDidUpdate(prevProps) {
      if ((prevProps?.openModal !== this.props.openModal)
        // && this.props.openModal
      ) {
        this.getSuppliers()
        this.getProducts()
      }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'Error']: e.target.value === '',
        })
    }

    getSuppliers = async () =>{
        API.get('inventory/backoffice/suppliers')
        
        .then(res => {
            if(res.status === 200){
                const entries = res.data.clients // backend must return suppliers not clients
                this.setState({
                    suppliers: entries
                 })
                
            } else {
                console.log("There was an error retrieving suppliers")
            }
        })
        .catch((error) => {
            console.error('There was an error retrieving suppliers, ', error);
        });
    }

    getProducts = async () => {
        API.get('inventory/backoffice/products')
        .then(res => {
            if(res.status === 200){
                const entries = res.data.products
                this.setState({
                    products: entries
                 })
                
            } else {
                console.log("There was an error retrieving products")
            }
        })
        .catch((error) => {
            console.error('There was an error retrieving products, ', error);
        });
    }

    submitOrder = async (e) =>{
        const endpoint = 'inventory/backoffice/supplier_order/'
        const final = endpoint.concat(this.state.supplier)
        const _supplierErr = this.state.supplier === '';
        const _productsErr = !this.state.productList?.length;
        this.setState({
            supplierError: _supplierErr,
            volumeError: _productsErr,
            productError: _productsErr,
        })
        if(_supplierErr || _productsErr) {
            return
        }

        API.post(final, this.state.productList)
        .then(res => {
            if(res.status === 201){
                this.handleClose();
                this.props.setUpdateStats(true);
                this.props.setUpdateAllOders(true);
                // window.location.reload()
            } else {
                this.setState({
                    alert: 'There was an error processing your order...'
                })
            }
        })
        .catch((error) => {
            this.setState({
                alert: 'There was an error processing your order.',
            });
            console.error('There was an error submitting your order, ', error);
        });
    }

    handleClose = () => {
        const { productList } = this.state;
        productList.splice(0, productList.length);
        this.setState({ ...initState });
        this.props.toggleModalState("buy", false);
    }

    addToList = (product, volume) =>{
        const { productList } = this.state;
        const exists = productList.length > 0 && productList.filter(p => p.product === product).length > 0;

        const _volErr = volume === '' ;
        const _prodErr = product === '' || exists;
        this.setState({
            'productError': _prodErr,
            'volumeError': _volErr,
        })
        if (_prodErr || _volErr || exists) {
            return
        }

        productList.push({ product, volume });
        this.setState({
            productList: productList,
            product: '',
            volume: '',
        });
    }

    removeFromList = (index) => {
        const { productList } = this.state;
        productList.splice(index, 1);
        this.setState({ productList: productList });
      }

    render(){
        const volumeLimit = this.props.orderLimits.volume;
        const volumeErrorMessage = (volumeLimit.max < 1000000
            ? 'Volume must be between ' + volumeLimit.min + ' and ' + volumeLimit.max
            : 'Volume must be ' + volumeLimit.min + ' or more'
        );

        return(
         <Stack>
          <CustomModal
            openModal={this.props.openModal}
            handleCloseModal={this.handleClose}
            modalStyle={{
              width: { ...MODAL_WIDTH.small },
            }}
            modalTitle={'Buy Bulk Order'}
            modalIcon='currency'
          >
          <Typography>
            <em>Bulk Purchase</em>
          </Typography>
          <Stack id="modal-modal-description"  sx={{ mt: 2 }}>
          {/* <FormControl fullWidth sx={{my: 2}}>
             <InputLabel id="demo-simple-select-label">Supplier</InputLabel> */}
          <CustomTextField sx={{my: 2}}
              select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="supplier"
              value={this.state.supplier}
              label="Supplier"
            onChange={(e)=>{this.handleChange(e)}}
            error={this.state.supplierError}
            helperText={this.state.supplierError? 'Supplier is required': ''}
          >
              {this.state.suppliers.map((entry)=>(
                <MenuItem key={entry.company_id} value={entry.company_id}>{entry.company_name}</MenuItem>
              ))}
          </CustomTextField>
          {/* </FormControl> */}
        
        <Grid display={this.state.productList?.length !== 0 ? 'block' : 'none'} container alignItems={'center'} sx={{ my: 1 }}>
          {this.state.productList.map((entry, index)=>(
            <Grid container alignItems={'right'} key={"product-" + index} sx={{ my: 1 }}>
            <Grid item size={{ xs: 4 }} value={entry.product}>{entry.product}</Grid>
            <Grid item size={{ xs: 4 }} value={entry.volume}> {entry.volume}</Grid>
            <Grid item size={{ xs: 4 }}>
            <Button  variant='contained' onClick={() => {this.removeFromList(index)}}>-</Button>
            </Grid>
           
            </Grid>
              ))}
        </Grid>

        <Grid container component="form" noValidate alignItems={'right'}>
          <Grid item size={{ xs: 4 }}>
            <FormControl fullWidth>
             {/* <InputLabel id="demo-simple-select-label">Product</InputLabel> */}
            <CustomTextField
              select
              required
              id="product-select"
              name="product"
              value={this.state.product}
              label="Product"
              onChange={(e)=>{this.handleChange(e)}}
              error={this.state.productError}
              helperText={this.state.productError? 'Product is required or already added': ''}
            >
              {this.state.products.map((entry)=>(
                <MenuItem key={entry.name} value={entry.name}>{entry.name}</MenuItem>
              ))}
           </CustomTextField>
        </FormControl>
            </Grid>
            <Grid item size={{ xs: 4 }}>
            <FormControl fullWidth>
            <CustomTextField 
                required
                id="volume"
                label="Volume"
                variant="outlined"
                name="volume"
                value={this.state.volume}
                slotProps={{
                    input: {
                        min: volumeLimit.min,
                        max: volumeLimit.max,
                    },
                    inputLabel: { shrink: true }
                }}
                type="number"
                sx={{ ml: 2 }}
                onChange={(e) => {
                    this.handleChange(e);
                    if (e.target.value < volumeLimit.min || e.target.value > volumeLimit.max) {
                        this.setState({
                            volumeError: true,
                        })
                    }
                }}
                error={this.state.volumeError}
                helperText={this.state.volumeError? volumeErrorMessage: ''}
            />
        </FormControl>
            </Grid>
            <Grid item size={{ xs: 3}} sx={{ ml: 2 }} >
        <Button  variant='contained' sx={{ mt: 2 }} onClick={(e) => {this.addToList(this.state.product, this.state.volume)}}>Add</Button>
            </Grid>

        </Grid>
        <Box sx={{ alignItems: 'center' }}>
            <Button color="inherit" variant='contained' sx={{ mt: 2, width: '47%' }} onClick={() => {this.handleClose()}}>Cancel</Button>
            <Button  variant='contained' sx={{ mt: 2, ml: 2,  width: '47%' }} onClick={(e) => {this.submitOrder(e)}}>Submit</Button>
        </Box>
          <Typography sx={{ mt: 2 }}>
            {this.state.alert}
          </Typography>
          </Stack>
      </CustomModal>
      <Modal open={this.state.openCheckModal}>
        <p>The product you are adding has already been added, would you like to add the volume to the current order?</p>
      </Modal>
         </Stack>
        )
    }
}

BuyModal.propTypes = {
    setUpdateStats: PropTypes.func.isRequired,
    setUpdateAllOders: PropTypes.func.isRequired,
};
