/**
 * Utility functionality for validating form fields
*/

export const updateFormValue = (setFormData, formData, field, value, resetError = true) => {

  const newFormData = {
    ...formData,
    [field]: {
      ...formData[field],
      value: value,
      error: resetError ? false : formData[field].error,
    }
  }
  setFormData(newFormData);

  return newFormData;
}

export const updateFormError = (setFormData, formData, field, error) => {
  let newFormData = {
    ...formData,
    [field]: {
      ...formData[field],
      error: error
    }
  }

  setFormData(newFormData);

  return newFormData;
}

export const validateForm = (setFormData, formData) => {
  const formFields = Object.keys(formData);
  let isValid = true;
  let newFormData = { ...formData };
  formFields.forEach((field) => {
    const currentField = newFormData[field];
    const currentValue = currentField['value'];
    if ((currentValue === '' || currentValue === undefined || currentValue === null)
        &&  currentField['required']) {
      newFormData = updateFormError(setFormData, newFormData, field, true)
      isValid = false;
    }
  });

  return isValid;
}

export const initialiseForm = (intiData) => {
  const formFields = Object.keys(intiData);
  let formState = {};
  formFields.forEach((field) => {
    formState[field] = {
      required: intiData[field].required,
      value: intiData[field]?.value,
      error:false,
      errorMessage:'Please provide ' + field,
    }
  });

  return formState;
}

export const extractFormValues = (formData) => {

  const formFields = Object.keys(formData);
  let formValues = {};
  formFields.forEach((field) => {
    formValues[field] = formData[field].value;
  });

  return formValues;
}

export const isNotNullValue = (value) => {

  return (value !== undefined && value !== null && value !== '');
}

export const isNullValue = (value) => {

  return (value === undefined || value === null || value === '');
}
