import * as React from "react"
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { DASHBOARD_ICON_OPACITY_10, WHITE_SMOKE_COLOR } from "../../../utils/constants"
import { isNotNullValue } from '../../../utils/formUtil';

function getTrendColor(trend) {
  if (trend === "up") {
    return "success"
  } else if (trend === "down") {
    return "error"
  }

  return "primary"
}

function getBackgroundColor(trend) {
  if (trend === "up") {
    return "#2e7d32"
  } else if (trend === "down") {
    return "#d32f2f"
  }

  return "#1976d2"
}

export function ProgressCard({ data, sx={} }) {

  const progressColor = getTrendColor(data?.metadata?.trend)
  const trendColor = getBackgroundColor(data?.metadata?.trend)

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack
            direction="row"
            sx={{ alignItems: "flex-start", justifyContent: "space-between" }}
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography
                color="text.primary"
                gutterBottom
                variant="inherit"
              >
                {data?.type}
              </Typography>
              <Typography variant="subtitle1">{data?.value}</Typography>
            </Stack>
            {isNotNullValue(data?.metadata?.diff) && (
              <Box
               isNotNullValue sx={{
                  backgroundColor: ` ${trendColor}${DASHBOARD_ICON_OPACITY_10}`,
                  height: "45px",
                  width: "45px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: ` ${trendColor}`
                }}
              >
                {data?.metadata?.diff}%
              </Box>
            )}
          </Stack>
          <div>
            <LinearProgress
              sx={{
                backgroundColor: WHITE_SMOKE_COLOR,
                height: "5px"
              }}
              variant="determinate"
              value={data?.metadata?.progress}
              color={progressColor}
            />
          </div>
          {data?.metadata?.trend === "up" ||
            data?.metadata?.trend === "down" ? (
            <Stack
              sx={{ alignItems: "center", mt: 2 }}
              direction="row"
              spacing={2}
            >
              <Stack
                sx={{ alignItems: "center" }}
                direction="row"
                spacing={0.5}
              >
                {data?.metadata?.trend === "up" ? (
                  <ArrowUpwardIcon htmlColor={trendColor} />
                ) : (
                  <ArrowDownwardIcon htmlColor={trendColor} />
                )}
                {/* <Typography color={trendColor} variant="body2">
                  {diff}%
                </Typography> */}
              </Stack>
              <Typography color="text.primary" variant="caption">
                {data?.metadata?.indicator}
              </Typography>
            </Stack>
          ) : (
            <Typography color="text.primary">
              {data?.metadata?.indicator}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
