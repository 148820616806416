import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, FormControl, MenuItem, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import API from '../../../data/api'; // Use the configured API instance
import CustomTextField from '../../custom/CustomTextField';
import CustomBox from '../../custom/CustomBox';
import CustomModal from '../../custom/CustomModal';
import { MODAL_WIDTH } from '../../../utils/constants';
import { LoadingButton } from '@mui/lab';

const SupplierBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  borderRadius: theme.shape.borderRadius,
}));

const AvailableBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: '#ADD8E6',
  color: theme.palette.getContrastText('#ADD8E6'),
  borderRadius: theme.shape.borderRadius,
}));

const AllocatedBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: '#800080',
  color: theme.palette.getContrastText('#800080'),
  borderRadius: theme.shape.borderRadius,
}));

const OrderBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(1),
  backgroundColor: '#FFD700',
  color: theme.palette.getContrastText('#FFD700'),
  borderRadius: theme.shape.borderRadius,
}));

const EditModal = ({ open, handleClose, subOrder, handleSubmit }) => {
  const [transporters, setTransporters] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [depots, setDepots] = useState([]);
  const [selectedTransporter, setSelectedTransporter] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedDepot, setSelectedDepot] = useState('');
  const [bursModalOpen, setBursModalOpen] = useState(false);
  const [awbNumber, setAwbNumber] = useState('');
  const [suborderTotalCost, setSuborderTotalCost] = useState('');
  const [taxAndDutiesCost, setTaxAndDutiesCost] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [bursPdf, setBursPdf] = useState(null);
  const [isInvoiceNumberValid, setIsInvoiceNumberValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!subOrder) return;

      const productID = subOrder.product_id;
      const volume = subOrder.volume;

      try {
        const [transportersResponse, suppliersResponse, depotsResponse] = await Promise.all([
          API.get('inventory/backoffice/logistics'),
          API.get(`inventory/backoffice/valid_suppliers?product_id=${productID}&current_volume=${volume}`),
          API.get('inventory/backoffice/depots?depot_type=storage')
        ]);

        setTransporters(transportersResponse.data.clients);
        setSuppliers(suppliersResponse.data);
        setDepots(depotsResponse.data.depots);

        if (subOrder) {
          const placeholderData = await fetchSuborderPlaceholders(subOrder.suborder_id);
          if (placeholderData) {
            const { transporter, supplier, depot } = placeholderData;

            setSelectedTransporter(transporter ? transporter.company_id : '');
            setSelectedSupplier(supplier ? supplier.supplier_name : '');
            setSelectedDepot(depot ? depot.depot_name : '');
          }
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    if (open) {
      fetchInitialData();
    } else {
      setSelectedTransporter('');
      setSelectedSupplier('');
      setSelectedDepot('');
    }
  }, [open, subOrder]);

  const fetchSuborderPlaceholders = async (suborderId) => {
    try {
      const response = await API.get(`inventory/backoffice/suborder_placeholders/${suborderId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching suborder placeholders:', error);
      return null;
    }
  };

  const handleFormSubmit = () => {
    const transportId = transporters.find(t => t.company_id === selectedTransporter)?.company_id || null;
    const supplier = suppliers.find(s => s.order_id === selectedSupplier);
    const supplierId = supplier?.supplier_id || null;
    const supplierOrderId = supplier?.order_id || null;
    const supplierOrderItemId = supplier?.order_item_id || null;
    const depotId = depots.find(d => d.depot_id === selectedDepot)?.depot_id || null;

    setSubmitted(true)
    handleSubmit(subOrder.suborder_id, transportId, supplierId, depotId, supplierOrderId, supplierOrderItemId);
    handleClose();
  };

  const handleBursModalOpen = () => {
    setBursModalOpen(true);
  };

  const handleBursModalClose = () => {
    setBursModalOpen(false);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error('No PDF file selected');
      return;
    }
    setBursPdf(file);

    try {
      const formData = new FormData();
      formData.append('file', file);

      // Send the file to the analytics endpoint
      const response = await API.post(
        'analytics/burs_extractor',
        formData,
        {
          headers: {
            'accept': 'application/json',
            'api-secret-key': 's3cr3t',
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const content = response.data;

      if (content) {
        setAwbNumber(content.awb || '');
        setSuborderTotalCost(content.suborder_cost || '');
        setTaxAndDutiesCost(content.tax || '');
        setInvoiceNumber(content.invoice_number || '');
        setIsInvoiceNumberValid(!!content.invoice_number);
      }
    } catch (error) {
      console.error('Error uploading BURS document:', error);
    }
  };

  const handleBursFormSubmit = async () => {
    if (!invoiceNumber) {
      setIsInvoiceNumberValid(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', bursPdf);
    formData.append('suborder_id', subOrder.suborder_id);
    formData.append('invoice_number', invoiceNumber);
    formData.append('suborder_cost', suborderTotalCost);
    formData.append('tax_cost', taxAndDutiesCost);

    const queryParams = new URLSearchParams({
      suborder_id: subOrder.suborder_id,
      invoice_number: invoiceNumber,
      suborder_cost: suborderTotalCost,
      tax_cost: taxAndDutiesCost
    }).toString();

    try {
      await API.post(`sharedfiles/upload_burs_document?${queryParams}`, formData, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      handleBursModalClose();
    } catch (error) {
      console.error('Error submitting BURS form:', error);
    }
  };

  useEffect(() => {
    setIsInvoiceNumberValid(invoiceNumber !== '');
  }, [invoiceNumber]);

  if (!subOrder) return null; // Render nothing if subOrder is null

  return (
    <>
      <CustomModal
        openModal={open}
        handleCloseModal={handleClose}
        modalStyle={{
          width: { ...MODAL_WIDTH.small },
        }}
        modalTitle={`Suborder ${subOrder.suborder_id} Allocation`}
        modalIcon='transport'
      >
          <FormControl fullWidth sx={{ mt: 3 }}>
            <CustomTextField
              select
              labelId="transport-select-label"
              value={selectedTransporter}
              label="Select Transport"
              onChange={(e) => setSelectedTransporter(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <em>Select Transporter</em>
              </MenuItem>
              {transporters.map((transporter) => (
                <MenuItem key={transporter.company_id} value={transporter.company_id}>
                  {transporter.company_name}
                </MenuItem>
              ))}
            </CustomTextField>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            {/* <InputLabel id="supplier-select-label">Select Supplier</InputLabel> */}
            <CustomTextField
              select
              labelId="supplier-select-label"
              value={selectedSupplier}
              label="Select Supplier"
              onChange={(e) => setSelectedSupplier(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select Supplier</em>
              </MenuItem>
              {suppliers.map((supplier) => (
                <MenuItem key={"supplier-order-" + supplier.order_id} value={supplier.order_id}>
                  <SupplierBox title="Supplier Name">{supplier.supplier_name}</SupplierBox>
                  <AvailableBox title="Available Volume">{supplier.available_volume}</AvailableBox>
                  <AllocatedBox title="Allocated Volume">{supplier.allocated_volume}</AllocatedBox>
                  <OrderBox title="Order Item ID">{supplier.order_item_id}</OrderBox>
                </MenuItem>
              ))}
            </CustomTextField>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            {/* <InputLabel id="depot-select-label">Select Depot</InputLabel> */}
            <CustomTextField
              select
              labelId="depot-select-label"
              value={selectedDepot}
              label="Select Depot"
              onChange={(e) => setSelectedDepot(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select Depot</em>
              </MenuItem>
              {depots.map((depot) => (
                <MenuItem key={depot.depot_id} value={depot.depot_id}>
                  {depot.depot_name}
                </MenuItem>
              ))}
            </CustomTextField>
          </FormControl>

          {/* <Button 
            variant="contained"
            sx={{ mt: 2, mr: 2, backgroundColor: 'orange' }} 
            onClick={handleBursModalOpen}>
            Upload BURS Document
          </Button> */}


        <Box sx={{ alignItems: 'center', mt: 3, }}>
          <Button
            color="inherit"
            variant='contained'
            sx={{ width: '47%' }}
            onClick={() => { handleClose() }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant='contained'
            sx={{ ml: 2, width: '47%' }}
            type="submit"
            loading={submitted}
            onClick={() => { handleFormSubmit() }}
          >
            Submit
          </LoadingButton>
        </Box>
 {/*
          <Button variant="contained" sx={{ mt: 2 }} onClick={handleFormSubmit}>
            Submit
          </Button>
          <Button
            variant='contained'
            sx={{ mt: 2, ml: 2}}
            component="label"
            color='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button> */}
      </CustomModal>

      <Modal
        open={bursModalOpen}
        onClose={(e, r) => {
          if (r && (r === "backdropClick" | r === "escapeKeyDown")) return;
          else handleBursModalClose();
        }}
        aria-labelledby="burs-modal-title"
        aria-describedby="burs-modal-description"
      >
        <CustomBox sx={{ width: 600}}>
          <Typography variant="h6" component="h2">
            Upload BURS Document
          </Typography>
          <CustomTextField
            fullWidth
            sx={{ mt: 2 }}
            label="AWB Number"
            value={awbNumber}
            onChange={(e) => setAwbNumber(e.target.value)}
          />
          <CustomTextField
            fullWidth
            sx={{ mt: 2 }}
            label="Invoice Number"
            value={invoiceNumber}
            onChange={(e) => {
              setInvoiceNumber(e.target.value);
              setIsInvoiceNumberValid(e.target.value !== '');
            }}
            error={!invoiceNumber} // Highlight if the field is empty
            helperText={!invoiceNumber ? 'Invoice number is required' : ''}
          />
          <CustomTextField
            fullWidth
            sx={{ mt: 2 }}
            label="Suborder Total Cost"
            value={suborderTotalCost}
            onChange={(e) => setSuborderTotalCost(e.target.value)}
          />
          <CustomTextField
            fullWidth
            sx={{ mt: 2 }}
            label="Tax and Duties Cost"
            value={taxAndDutiesCost}
            onChange={(e) => setTaxAndDutiesCost(e.target.value)}
          />
          {/* <Button
            variant="contained"
            component="label"
            sx={{ mt: 2, mr: 2, backgroundColor: 'purple' }}
          >
            Upload BURS PDF
            <input
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </Button> */}

          <Box sx={{ alignItems: 'center',  mt: 3, }}>
              <Button color="inherit"
                variant='contained'
                sx={{ width: '47%' }}
                onClick={() => { handleBursModalClose() }}
              >
                Cancel
              </Button>

              <LoadingButton
                variant='contained'
                sx={{ml: 2, width: '47%' }}
                type="submit"
                loading={submitted}
                onClick={() => {handleBursFormSubmit()}}
              >
                Submit
              </LoadingButton>
            </Box>
        </CustomBox>
      </Modal>
    </>
  );
};

export default EditModal;

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  subOrder: PropTypes.object.isRequired,
}
