import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material';

const CustomTextField = ({ InputLabelProps = {}, ...props }) => {
    return (
    <TextField
        noValidate
        slotProps={{ 
            inputLabel: { ...InputLabelProps, shrink: true}
        }}
        {...props}
    />
    );
}

CustomTextField.propTypes = {
    InputLabelProps: PropTypes.object,
    props: PropTypes.any,
}

export default CustomTextField
