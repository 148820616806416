/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

const CustomDatePicker = ({
  label,
  field,
  handleChange,
  currentValue,
  minDate,
  clear,
  setClear,
  disableFuture = false,
  disablePast = true,
  fullWidth = true,
  dateError = ''
}) => {

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(currentValue);
  const [error, setError] = React.useState(dateError);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        return `Please ensure date is after ${minDate}`;
      }
      case 'invalidDate': {
        return 'Please enter a valid date';
      }
      default: {
        return '';
      }
    }
  }, [error]);

  useEffect(() => {
    if (clear) {
      setValue(null);
      setClear(false);
    }
    if(currentValue){
      setValue(dayjs(currentValue || null));
    }
    setError(dateError);
  }, [clear, dateError, currentValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          disablePast={disablePast}
          disableFuture={disableFuture}
          label={label}
          value={value}
          minDate={dayjs(minDate || null)}
          format={'YYYY-MM-DD'}
          onChange={(e) => {
            if (e) {
              handleChange(field, moment(e.$d).format('YYYY-MM-DD'));
              setValue(e);
            }
          }}
          open={open}
          onClose={() => setOpen(false)}
          onError={(newError) => setError(newError)}
          slotProps={{
            textField: {
              clearable: false,
              required: true,
              inputLabel: { shrink: true },
              onClick: () => setOpen(true),
              size: 'medium',
              fullWidth: fullWidth,
              error: !!errorMessage,
              helperText: errorMessage,
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

CustomDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  clear: PropTypes.bool.isRequired,
  setClear: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
}

export default CustomDatePicker
