
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { FilePdfFill } from 'react-bootstrap-icons';
import {
  AttachFile,
  Description,
} from "@mui/icons-material"

export const handlePreviewIcon = (fileObject) => {
  const { type } = fileObject
  const iconProps = {
  }

  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <Description {...iconProps} />
    case "application/pdf":
      return <FilePdfFill color='purple' />
    default:
      return <AttachFile {...iconProps} />
  }
}